<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px">
				 	<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 200px" />
				</el-form-item>
				<el-form-item label="年级" label-width="50px">
					<el-select v-model="queryForm.nianji" placeholder="请选择年级" size="medium" clearable style="width: 200px">
						<el-option :value="item.name" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
					</el-select>
				</el-form-item>
				<el-form-item label="学科" label-width="50px">
					<el-select v-model="queryForm.xueke" placeholder="请选择学科" size="medium" clearable style="width: 200px">
						<el-option :value="item.name" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
					</el-select>
				</el-form-item>
<!-- 				<el-form-item label="排序" label-width="50px">
					<el-select v-model="queryForm.field" placeholder="请选择字段" size="medium" clearable style="width: 200px" class="mr-1">
						<el-option :value="item.label" :label="item.name" v-for="(item,index) in fields" :key="item.id"/>
					</el-select>
					<el-select v-model="queryForm.order" placeholder="选择排序" size="medium" clearable style="width: 120px">
						<el-option value="ASC" label="正序" />
						<el-option value="DESC" label="倒序" />
					</el-select>
				</el-form-item> -->
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<el-table
			    :data="list"
			    border
				:empty-text="loadInfo"
			    style="width: 100%">
				<el-table-column label="序号" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
			    <el-table-column
			      prop="nianji"
			      label="年级"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="t_code"
				  align="center"
			      label="编码">
			    </el-table-column>
				<el-table-column
				  prop="t_name"
				  align="center"
				  label="姓名">
				</el-table-column>
				<el-table-column
				  prop="xueke"
				  align="center"
				  label="学科">
				</el-table-column>
				<el-table-column
				  prop="mobile"
				  align="center"
				  width="120"
				  label="电话">
				</el-table-column>
				<el-table-column
				  prop="dkl_jwc_score"
				  align="center"
				  label="代课量">
				</el-table-column>
				<el-table-column
				  prop="jxcg_jwc_score"
				  align="center"
				  label="教学常规">
				</el-table-column>
				<el-table-column
				  prop="jtbk_jwc_score"
				  align="center"
				  label="集体备课">
				</el-table-column>
				<el-table-column
				  prop="jyhd_jwc_score"
				  align="center"
				  label="教研工作">
				</el-table-column>
				<el-table-column
				  prop="hdzd_total"
				  align="center"
				  label="指导学生">
				</el-table-column>
				<el-table-column
				  prop="jxcj_total"
				  align="center"
				  label="教学成绩">
				</el-table-column>
				<el-table-column
				  prop="ungk_score"
				  align="center"
				  width="100"
				  label="非高考科目">
				</el-table-column>
				<el-table-column
				  prop="jsry_score"
				  align="center"
				  label="教师荣誉">
				</el-table-column>
				<el-table-column
				  prop="jzjx_score"
				  align="center"
				  label="兼职绩效">
				</el-table-column>
				<el-table-column
				  prop="dwjk_score"
				  align="center"
				  label="对外监考">
				</el-table-column>
				<el-table-column
				  prop="jlcf_score"
				  align="center"
				  label="纪律处罚">
				</el-table-column>
				<el-table-column
				  prop="total"
				  align="center"
				  label="总分">
				</el-table-column>
			  </el-table>
		</el-card>
		<pagination
			:total="total"
			auto-scroll
			:page.sync="queryForm.page"
			:limit.sync="queryForm.limit"
			@pagination="query"
		/>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'semester',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 15,
					keyword: '',
					nianji:'',
					xueke:'',
					field:'',
					order:'',
				},
				id:'',
				s_id:'',
				list:[],
				loadInfo:'',
				nianji:[],
				xueke:[],
				fields:[
					{id:1,name:'代课量',label:'dkl'},
					{id:2,name:'教学常规',label:'jxcg'},
					{id:3,name:'集体备课',label:'jtbk'},
					{id:4,name:'教研工作',label:'jygz'},
					{id:5,name:'指导学生',label:'zdxx'},
					{id:6,name:'教学成绩',label:'jxcj'},
					{id:7,name:'非高考科目',label:'ungk'},
					{id:8,name:'教师荣誉',label:'jsry'},
					{id:9,name:'兼职绩效',label:'jzjx'},
					{id:10,name:'对外监考',label:'dwjk'},
					{id:11,name:'纪律处罚',label:'jlcf'},
					{id:12,name:'总分',label:'total'},
				],
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
			this.getNianji()
			this.getXueke()
			this.getList()
		},
		computed:{
			
		},
		methods:{
			getNianji(){
				this.axios.get('/manage/nianji/all').then(res=>{
					if(res.status){
						this.nianji = res.data
					}
				})
			},
			getXueke(){
				this.axios.get('/manage/xueke/all').then(res=>{
					if(res.status){
						this.xueke = res.data
					}
				})
			},
			getList(){
				this.list = []
				this.loadInfo = '数据加载中...'
				this.axios.get('/manage/'+this.preUrl+'/view',{
					params:{
						page:this.queryForm.page,
						limit:this.queryForm.limit,
						keyword:this.queryForm.keyword,
						nianji:this.queryForm.nianji,
						xueke:this.queryForm.xueke,
						field:this.queryForm.field,
						order:this.queryForm.order,
						id:this.id
					}
				}).then(res=>{
					if(res.status){
						this.total = res.data.total
						this.list = res.data.list
						this.loadInfo = ''
					}
				})
			},
		}
	}
</script>

<style>
</style>